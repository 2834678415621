<template>
    <a-table :columns="columns" :data-source="list">
        <template #action="{record}">
            <router-link :to="'/admin/role/add?id='+record.id" v-if="hasAuth('role:edit')">编辑</router-link>&emsp;
            <a @click="del(record.id)" class="red" v-if="hasAuth('role:del')">删除</a>
        </template>
    </a-table>
</template>

<script>
    import {createVNode, defineComponent, onMounted, ref} from 'vue';
    import {message, Modal} from "ant-design-vue";
    import {roleList,delRole} from "@/network/admin";
    import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
    export default defineComponent({
        name: "Rlist",
        setup() {
            let list = ref([]);
            const columns = [
                {
                    title: 'Id',
                    /*width: 50,*/
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '角色名',
                    // width: 60,
                    dataIndex: 'name',
                    key: 'name',
                },{
                    title: '操作',
                    key: 'operation',
                    // width: 50,
                    fixed: 'right',
                    slots: {
                        customRender: 'action',
                    },
                }]
            const del = (id) => {
                Modal.confirm({
                    title: '你确定要删除角色?',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '请选择是或否',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        delRole({'id': id}).then((response) => {
                            if (response.status == 'done') {
                                message.success(response.msg);
                                getList()
                            } else {
                                message.error(response.msg);
                            }
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                })
            };
            const getList = () => {
                roleList().then((response) => {
                    //console.log(response);
                    if (response.status == 'done') {
                        //console.log(response.list);
                        list.value = response.list;
                    } else {
                        message.error(response.msg);
                    }
                })
            };
            onMounted(()=>{
                getList();
            })
            return {
                columns,
                del,
                list
            }
        }
    })
</script>

<style scoped>
 .red{
     color: red;
 }
</style>
